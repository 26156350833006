import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';
//import { useTheme } from '@emotion/react';
import useTheme from '@mui/system/useTheme';

import debounce from 'lodash/debounce';

import SearchIconLight from '../resources/search-icon.svg';
import SearchIconDark from '../resources/search-icon-dark.svg';

const SearchInput = styled(InputBase)`
    background: no-repeat scroll 7px 7px;
    background-size: 20px 20px;
    background-position: 12px center;
    background-color: ${({ theme }) => theme.palette.colors.transparentWhite2};
    background-image: url(${({ theme }) => theme.palette.mode === 'light' ? SearchIconLight : SearchIconDark});
    border: 1.5px solid ${({ theme }) => theme.palette.common.backgroundOutline};
    color: ${({ theme }) => theme.palette.text.secondary};
    height: 46px;
    border-radius: 12px;
    padding: 12px 16px 12px 40px;
    width: 520px;
    :hover:not(:focus-within) { border-color: ${({ theme }) => theme.palette.colors.primary3}; }
    ::placeholder { color: ${({ theme }) => theme.palette.text.tertiary}; }
`;

const SearchInputResults = styled(Box)`
    background-color: ${({ theme }) => theme.palette.colors.white};
    border: 1.5px solid ${({ theme }) => theme.palette.common.backgroundOutline};
    border-top: none;
    transition-duration: 200ms;
    border-radius: 0px 0px 12px 12px;
    padding-bottom: 12px;
    padding-top: 12px;
    gap: 12px;
    width: 518px;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
`;

const RowStyle = styled(Box)`
    width: calc(100% - 56px);
`;

const EmptyRow = styled(RowStyle)`
    text-align: center;
`;

const ResultRow = styled(RowStyle)`
    padding: 8px 16px 8px 40px;
    cursor: pointer;
    &:hover { background-color: ${({ theme }) => theme.palette.colors.primaryTransparent2}; }
`;

const ResultHeader = styled(RowStyle)`
    color: ${({ theme }) => theme.palette.colors.text3};
    font-size: 14px;
`;

const SlashIcon = styled(Box)`
border: 1.5px solid ${({ theme }) => theme.palette.common.backgroundOutline};
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 23px;
    width: 23px;
    border-radius: 6px;
    margin-left: 10px;
`;

const QuickOpenIcon = () => (
    <SlashIcon>
        /
    </SlashIcon>
) 

const NavbarSearch = () => {
    const [error, setError] = useState(null); // Error state
    const location = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filterString, setFilterString] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(0); // used for keypress event tracking
    const resultsRef = useRef(null);
    const inputRef = useRef(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SAS_URL}/platform/view/chainlets`);
            const data = await response.json();
            if (data.code === 200) {
                setData(data.result);
            } else {
                throw new Error("Failed to fetch chainlets data");
            }
        } catch (error) {
            console.error('Error fetching chainlets data:', error);
            setError(error.message);
        }
        setLoading(false);
    };

    // debounce the fetch data to prevent too many request being called as filter string changes
    const debouncedFetchData = debounce(fetchData, 500);
    useEffect(() => { debouncedFetchData(); return () => debouncedFetchData.cancel(); }, [filterString]);

    // fetch data after client is ready
    useEffect(() => { if (!data) fetchData(); }, []);

    // filter the data based on filterString to be rendered
    const filterChainlets = useMemo(() => {
        if (!data) return { chainletsWithFilterStringStarting: [], chainletsWithFilterStringIncluded: [] };
        
        if (!filterString) return { chainletsWithFilterStringStarting: data, chainletsWithFilterStringIncluded: [] };

        const filteredChainlets = data.filter(chain => chain.name.includes(filterString));
        const chainletsWithFilterStringStarting = filteredChainlets.filter(chain => chain.name.startsWith(filterString));
        const chainletsWithFilterStringIncluded = filteredChainlets.filter(chain => !chain.name.startsWith(filterString));

        return {
            chainletsWithFilterStringStarting: chainletsWithFilterStringStarting.sort((a, b) => a.name.localeCompare(b.name)),
            chainletsWithFilterStringIncluded: chainletsWithFilterStringIncluded.sort((a, b) => a.name.localeCompare(b.name)),
        };
    }, [data, filterString]);

    // reset the search index anytime user navigates
    useEffect(() => { setSelectedIndex(0); }, [location]);

    // handle keypress logic for search bar
    const handleKeyPress = (event) => {
        const { key } = event;
        const children = resultsRef.current?.childNodes;
        const totalChildren = children ? children.length - 2 : 0;

        if (key === 'ArrowDown') { // ArrowDown: Move down the selection or wrap to the start
            event.preventDefault();

            // ArrowDown with specific ariaLabel: Open search results if closed
            if (!resultsRef.current && event.target.ariaLabel === 'navsearch') {
                setIsFocused(true);
            } else {
                setSelectedIndex(selectedIndex >= totalChildren ? 0 : selectedIndex + 1);
            }
        } else if (key === 'ArrowUp') { // ArrowUp: Move up the selection or wrap to the end
            event.preventDefault();
            setSelectedIndex(selectedIndex <= 0 ? totalChildren : selectedIndex - 1);
        } else if (key === 'Enter') { // Enter: Select the highlighted item
            event.preventDefault();
            children?.[selectedIndex + 1]?.click();
        } else if (key === 'Escape') { // Escape: Close the search results
            event.preventDefault();
            setIsFocused(false);
        } else if (key === '/') { // Backslash: Open the search and set focus
            event.preventDefault();
            inputRef.current.children[0].focus();
            setIsFocused(true);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [selectedIndex]);

    // navigates to chainlet detail page for corresponding chainlet that was clicked
    const handleRowClick = (chainletName) => {
        navigate(`/chainlets/${chainletName}`);
        setIsFocused(false);
        inputRef.current.children[0].blur();
        setSelectedIndex(0);
        setFilterString("");
    };

    return (
        <Box>
            <SearchInput
                endAdornment={<QuickOpenIcon />}
                ref={inputRef}
                type="search"
                placeholder="Search…"
                inputProps={{ 'aria-label': 'navsearch' }}
                id="navSearchBar"
                autoComplete="off"
                value={filterString}
                onChange={({ target: { value } }) => {
                    setFilterString(value);
                    setIsFocused(value ? true : false);
                    setSelectedIndex(0);
                }}
                onClick={() => setIsFocused(true) }
                onBlur={() => { setTimeout(() => { setIsFocused(false) }, 200); }}
                sx={isFocused ? {
                    backgroundColor: theme.palette.colors.white,
                    outline: 'none',
                    borderColor: theme.palette.common.accentActionSoft,
                    borderRadius: '12px 12px 0px 0px',
                    borderLeft: `1.5px solid  ${theme.palette.common.backgroundOutline}`,
                    borderRight: `1.5px solid  ${theme.palette.common.backgroundOutline}`,
                    borderTop: `1.5px solid  ${theme.palette.common.backgroundOutline}`,
                    borderBottom: 'none'
                } : {}}
            />
            {isFocused && (
                <SearchInputResults ref={resultsRef}>
                    <ResultHeader>Chainlets</ResultHeader>
                    {loading && !data && <CircularProgress />}
                    {data && (
                        [...filterChainlets.chainletsWithFilterStringStarting, ...filterChainlets.chainletsWithFilterStringIncluded]
                            .slice(0, 10)
                            .map((chain, index) => (
                                <ResultRow
                                    key={index}
                                    onClick={() => handleRowClick(chain.chainId)}
                                    style={index === selectedIndex ? { backgroundColor: theme.palette.colors.primaryTransparent2 } : null}
                                >
                                    {`${chain.name} ${chain.denom.toUpperCase()}`}
                                </ResultRow>
                            ))
                    )}
                    {data && [...filterChainlets.chainletsWithFilterStringStarting, ...filterChainlets.chainletsWithFilterStringIncluded].length === 0 && <EmptyRow>No chainlets found.</EmptyRow>}
                </SearchInputResults>
            )}
        </Box>
    );
};

export default NavbarSearch;