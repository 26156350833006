module.exports = {
  chainId: process.env.REACT_APP_SPC_CHAIN_ID,
  chainName: "Saga Platform Chain",
  rpc: process.env.REACT_APP_RPC_URL,
  rest: process.env.REACT_APP_REST_URL,
  stakeCurrency: {
    coinDenom: process.env.REACT_APP_SPC_COIN_DENOM,
    coinMinimalDenom: process.env.REACT_APP_SPC_COIN_MIN_DENOM.toLowerCase(),
    coinDecimals: 6,
  },
  bip44: {
    coinType: 118,
  },
  bech32Config: {
    bech32PrefixAccAddr: "saga",
    bech32PrefixAccPub: "sagapub",
    bech32PrefixValAddr: "sagavaloper",
    bech32PrefixValPub: "sagavaloperpub",
    bech32PrefixConsAddr: "sagavalcons",
    bech32PrefixConsPub: "sagavalconspub"
  },
  currencies: [{
    coinDenom: process.env.REACT_APP_SPC_COIN_DENOM,
    coinMinimalDenom: process.env.REACT_APP_SPC_COIN_MIN_DENOM.toLowerCase(),
    coinDecimals: 6,
  }],
  feeCurrencies: [{
    coinDenom: process.env.REACT_APP_SPC_COIN_DENOM,
    coinMinimalDenom: process.env.REACT_APP_SPC_COIN_MIN_DENOM.toLowerCase(),
    coinDecimals: 6,
    gasPriceStep: {
      low: 0,
      average: 0,
      high: 0,
    },
  }],
};