import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { Button } from '@mui/base';
import styled from '@mui/system/styled';

import useTheme from '@mui/material/styles/useTheme';

import { useAccountDrawer } from '../hooks/useAccountDrawer';

import AccountDrawerContainer from './AccountDrawerContainer';
import ToggleSwitch from './ToggleSwitch';

const AutoColumn = styled(Box)`
margin-top: 24px;
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 16px;
`;

const StyledDivider = styled(Divider)`
    background-color: ${({ theme }) => theme.palette.colors.borderAndFill};
    border: none;
    height: 0.5px;
    margin-top: 24px;
    margin-bottom: 24px;
`;

const AccountDrawerMobilePanel = ({ setSettingsOpen, toggleThemeType, themeType }) => {
    const theme = useTheme();
    const { toggleDrawer } = useAccountDrawer();

    const switchOptions = [
        { label: 'Light', value: 'light' },
        { label: 'Dark', value: 'dark' },
      ];

    const openInnovatorLink = () => {
        const newWindow = window.open("https://www.saga.xyz/join", '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const openDocsLink = () => {
        const newWindow = window.open("https://docs.saga.xyz/working-with-chainlets/", '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <AccountDrawerContainer title={<KeyboardDoubleArrowRightIcon onClick={() => toggleDrawer()} sx={{ fill: theme.palette.colors.text4}} />}
            setSettingsOpen={setSettingsOpen}>
            <AutoColumn>
                <Typography
                    onClick={() => toggleDrawer()}
                    sx={{ paddingLeft: '4px', color: theme.palette.colors.text2, textDecoration: 'none', fontWeight: '600' }}
                    component={Link}
                    to="/"
                >BROWSE CHAINLETS</Typography>
                <StyledDivider />
                <Typography
                    onClick={() => openInnovatorLink()}
                    sx={{ display: 'flex', gap: '15px', paddingLeft: '4px', color: theme.palette.colors.text2, textDecoration: 'none', fontWeight: '600' }}
                >BECOME AN INNOVATOR<OpenInNewIcon /></Typography>
                <StyledDivider />
                <Typography
                    onClick={() => openDocsLink()}
                    sx={{ display: 'flex', gap: '15px', paddingLeft: '4px', color: theme.palette.colors.text2, textDecoration: 'none', fontWeight: '600' }}
                >DOCS<OpenInNewIcon /></Typography>
                <StyledDivider />
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ display: 'flex', gap: '15px', color: theme.palette.colors.text2, fontWeight: '600' }}>THEME: </Typography>
                    <ToggleSwitch options={switchOptions} activeOption={themeType} onToggle={toggleThemeType} />
                </Box>
            </AutoColumn>
        </AccountDrawerContainer>
    );
};

export default AccountDrawerMobilePanel;