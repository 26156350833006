import React from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';

const SwitchButton = styled(Box)`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 12px;
    color: ${({ theme, active }) => active ? theme.palette.colors.text1 : 'inherit'};
    background: ${({ theme, active }) => active ? theme.palette.colors.primary1 : 'none'};
`;

const SwitchContainer = styled(Box)`
    font-size: 16px;
    color: ${({ theme }) => theme.palette.colors.text1};
    user-select: none;
    box-sizing: border-box;
    display: flex;
    padding: 4px;
    border: 1px solid ${({ theme }) => theme.palette.colors.borderAndFill};
    border-radius: 16px;
    background: ${({ theme }) => theme.palette.colors.white};
`;

const ToggleSwitch = ({ sx, options, activeOption, onToggle }) => {
  return (
    <SwitchContainer sx={sx}>
      {options.map((option, index) => (
        <SwitchButton
          key={index}
          active={activeOption === option.value ? "true" : undefined}
          onClick={() => onToggle(option.value)}
        >
          {option.label}
        </SwitchButton>
      ))}
    </SwitchContainer>
  );
};

export default ToggleSwitch;