import React, { createContext, useState, useContext, useEffect } from 'react';

const StackDataContext = createContext();

export const useFetchStack = () => useContext(StackDataContext);

export const StackDataProvider = ({ children }) => {
    const [stack, setStack] = useState(null);
    const [loadingStack, setLoadingStack] = useState(true);
    const [stackError, setStackError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingStack(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_SAS_URL}/platform/view/launch`);
                const json = await response.json();

                if (json.code === 200 && json.result.stacks.length > 0) {
                    const defaultStackInfo = json.result.defaultStack;
                    // Find the default stack by comparing display names case insensitively
                    const defaultStack = json.result.stacks.find(stack => 
                        stack.displayName.toLowerCase() === defaultStackInfo.displayName.toLowerCase());

                    // If a default stack is found, add the defaultVersion to it
                    if (defaultStack) {
                        defaultStack.defaultVersion = defaultStackInfo.version; // Add the default version to the stack
                        setStack(defaultStack); // Set this stack as the default stack
                    } else {
                        // Fallback to the first stack if no matching default is found
                        const fallbackStack = json.result.stacks[0];

                        fallbackStack.defaultVersion = fallbackStack?.versions[fallbackStack?.versions.length - 1]; // Rely on symlink - take last/highest
                        setStack(fallbackStack);
                    }
                } else {
                    throw new Error('No stack data found');
                }
            } catch (err) {
                setStackError(err.message);
            } finally {
                setLoadingStack(false);
            }
        };

        fetchData();
    }, []);

    return (
        <StackDataContext.Provider value={{ stack, loadingStack, stackError }}>
            {children}
        </StackDataContext.Provider>
    );
};
