import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import { Button } from '@mui/base';
import styled from '@mui/system/styled';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useTheme from '@mui/material/styles/useTheme';
import CircularProgress from '@mui/material/CircularProgress';

import { copyToClipboard } from '../utils/copyToClipboard';
import abbreviatePublicAddress from '../utils/abbreviatePublicAddress';

import { useFetchStack } from '../hooks/useFetchStack';
import { useKeplr } from '../hooks/useKeplr';

import AccountDrawerContainer from './AccountDrawerContainer';
import OptionButton from './OptionButton';

const AutoColumn = styled(Box)`
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 16px;
`;

const AutoRow = styled(Box)`
    display: inline-flex;
`;

const BalanceLabel = styled(Typography)`
    color: ${({ theme }) => theme.palette.colors.text1};
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
`;

const BalanceDenomLabel = styled(Box)`
    padding-left: 4px;
    padding-top: 3px;
    line-height: 27px;
    font-size: 21px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.colors.text5};
`;

const RequestTokenButton = styled(Button)`
    font-family: 'Inter',sans-serif;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.colors.primary1};
    border: 0px;
    color: ${({ theme }) => theme.palette.colors.text1};
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    transition: background-color 125ms ease-in 0s;
    border-radius: 12px;
    height: 40px;
    &:hover {
        background-color: ${({ theme }) => theme.palette.colors.primaryTransparent1};
    }
    &:active {
        background-color: ${({ theme }) => theme.palette.colors.white};
    }
    :disabled {
        opacity: 0.4;
        cursor: auto;
        pointer-events: none;
    }
`;

const Input = styled(InputBase)`
    background-color: ${({ theme }) => theme.palette.common.backgroundModule};
    border-radius: 12px;
    border: 1.5px solid ${({ theme }) => theme.palette.common.backgroundOutline};
    height: 40px;
    width: 100%;
    font-size: 14px;
    padding-left: 16px;
    padding-right: 8px;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-family: 'Inter',sans-serif;
    transition-duration: 125ms;
    :hover {
        background-color: ${({ theme }) => theme.palette.common.backgroundSurface};
    }
    :focus {
        outline: none;
        background-color: ${({ theme }) => theme.palette.common.backgroundSurface};
        border-color: ${({ theme }) => theme.palette.common.accentActionSoft};
    }
`;

const StyledDivider = styled(Divider)`
    background-color: ${({ theme }) => theme.palette.colors.borderAndFill};
    border: none;
    height: 0.5px;
    margin-top: 24px;
    margin-bottom: 24px;
`;

const PassphraseCopy = styled(Box)`
    color: ${({ theme }) => theme.palette.colors.text4};
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 16px;
`;

const MessageContainer = styled(Box)`
    font-size: 14px;
    width: 100%;
    text-align: center;
`;

const AccountDrawerAccountPanel = ({ setSettingsOpen }) => {
    const { keplrState, updateAccountBalance } = useKeplr();
    const [passphrase, setPassphrase] = useState("");
    const [error, setError] = useState("");
    const [isWaiting, setIsWaiting] = useState(false);
    const [success, setSuccess] = useState(false);
    const theme = useTheme();
    const { stack, loadingStack, stackError } = useFetchStack();

    const [iconVisibility, setIconVisibility] = useState({});
    const [clickedElement, setClickedElement] = useState("");

    const toggleVisibility = (key, value) => {
        setIconVisibility(prevState => ({ ...prevState, [key]: value }));
    }

    const handleCopy = (key, value) => {
        setClickedElement(key);
        copyToClipboard(value);
        setTimeout(() => {
            setClickedElement("");
        }, 500);
    }

    const handleRequestClick = async () => {
        setError("");
        setSuccess(false);
        setIsWaiting(true);
    
        const data = { passphrase, address: keplrState.account.address };
    
        try {
            const response = await fetch(process.env.REACT_APP_FAUCET_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
            
            const responseData = await response.json();
    
            setIsWaiting(false);
    
            if (response.ok) {
                // Success from server, but need to check if the transactionHash is not null
                if (responseData.result.transactionHash !== null) {
                    setSuccess(true);
                } else {
                    // Handle already funded or bad passphrase where "transactionHash" will be null
                    setError("Invalid or previously used passphrase. Please check your passphrase and try again.");
                }
            } else {
                // Handle various errors based on the response code
                console.log(responseData.result.message);
                switch(responseData.code) {
                    case 400: // Bad Request
                        setError("Bad request. Please check your passphrase and try again.");
                        break;
                    case 429: // Too Many Requests
                        setError(`Too many requests. Please wait ${responseData.result.wait} seconds before trying again.`);
                        break;
                    case 500: // Other execution error
                        setError("An unexpected error occurred. Please try again later.");
                        break;
                    default:
                        setError("We encountered an unexpected error. Please try again later.");
                }
            }
        } catch (error) {
            setError("We encountered an error while trying to communicate with the faucet. Please try again later.");
            console.error('Error:', error);
        } finally {
            setIsWaiting(false);
        }
    };
    
    const openInnovatorLink = () => {
        // Opening a new tab with the specified URL
        const newWindow = window.open("https://www.saga.xyz/join", '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => { if (success) updateAccountBalance(); }, [success]);
    useEffect(() => { updateAccountBalance(); }, []);

    return (
        <AccountDrawerContainer
            title={
                <>
                    <AutoRow sx={{ alignItems: 'center' }}>
                        <AccountCircleIcon fontSize="large" sx={{ color: theme.palette.colors.text2 }} />
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography
                                sx={{ paddingLeft: '4px', color: theme.palette.colors.text2, cursor: 'pointer' }}
                                onMouseEnter={() => toggleVisibility('address', true)}
                                onMouseLeave={() => toggleVisibility('address', false)}
                                onClick={() => handleCopy('address', keplrState.account.address)}
                            >{abbreviatePublicAddress(keplrState.account.address, 6, 4)}</Typography>
                            <ContentCopyIcon
                                sx={{
                                    scale: '.75',
                                    fill: clickedElement ? theme.palette.colors.text4 : iconVisibility.address ? theme.palette.colors.primary2 : 'transparent',
                                    paddingLeft: '5px',
                                    cursor: 'pointer',
                                    "&:hover": { transitionDuration: '125ms', opacity: '0.6' }
                                }} />
                        </Box>

                    </AutoRow>
                    <OptionButton type="settings" onClick={() => { setSettingsOpen(true) }} />
                </>
            }
            setSettingsOpen={setSettingsOpen}>
            <AutoRow>
                <BalanceLabel>{Math.round(parseFloat(keplrState.balance) * 100) / 100}</BalanceLabel>
                <BalanceDenomLabel>{stack?.epochFee.denom.toUpperCase()}</BalanceDenomLabel>
            </AutoRow>
            <AutoColumn>
                <StyledDivider />
                <PassphraseCopy>Need tokens? Enter the passphrase supplied by Saga to add your tokens to your wallet.</PassphraseCopy>
                <Input type="search" placeholder={'Passphrase'} autoComplete="off" value={passphrase} onChange={({ target: { value } }) => { setPassphrase(value) }} />
                {error && <MessageContainer sx={{ color: theme.palette.error.light }}>{error}</MessageContainer>}
                {success && <MessageContainer sx={{ color: theme.palette.success.main }}>Success! Enjoy your tokens.</MessageContainer>}
                <RequestTokenButton disabled={isWaiting || !passphrase} onClick={handleRequestClick}>{isWaiting ? <CircularProgress size={20} /> : `Request ${stack?.epochFee.denom.toUpperCase()} Tokens`}</RequestTokenButton>
                <StyledDivider />
                <PassphraseCopy>Like what you see? Are you interested in partnering with Saga?</PassphraseCopy>
                <RequestTokenButton onClick={openInnovatorLink}>Become an Innovator</RequestTokenButton>
            </AutoColumn>
        </AccountDrawerContainer>
    );
};

export default AccountDrawerAccountPanel;