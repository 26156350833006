import React from 'react';
import { Button } from '@mui/base';
import styled from '@mui/system/styled';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';

const StyledHelpButton = styled(Button)`
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 9999px;
    background-color: ${({ theme }) => theme.palette.colors.primary1};
    color: ${({ theme }) => theme.palette.colors.text1};
    border: 1px solid ${({ theme }) => theme.palette.colors.border};
    padding: 10px 12px;
    transition: color 125ms ease-in 0s;
    text-decoration: none !important;
    position: fixed;
    bottom: 3vw;
    right: 3vw;
    z-index: 99999;

    p {
        display: none;
    }

    svg {
        opacity: .8;
    }

    &:hover {
        background-color: ${({ theme }) => theme.palette.colors.borderAndFill2};
        text-decoration: none !important;

        p {
            display: block;
        }

        svg {
            display: none;
        }
    }
    &:focus {
        border: 1px solid ${({ theme }) => theme.palette.colors.border};
        text-decoration: none !important;
    }
    &:active {
        box-shadow: ${({ theme }) => theme.palette.colors.secondary1} 0px 0px 0px 1pt;
        border: 1px solid ${({ theme }) => theme.palette.colors.secondary2};
        text-decoration: none !important;
    }
`;

const HelpButton = () => {
    return (<StyledHelpButton href="https://docs.saga.xyz/working-with-chainlets/" target="_blank"><HelpIcon /><Typography>Need help?</Typography></StyledHelpButton>);
};

export default HelpButton;