import createTheme from '@mui/material/styles/createTheme';
import { colors } from './colors';

const createCustomTheme = (mode) => {
  const themeColors = colors(mode);
  return createTheme({
    palette: {
      mode,
      primary: themeColors.primary,
      secondary: themeColors.secondary,
      text: themeColors.text,
      background: themeColors.background,
      common: themeColors.common,
      colors: {
        ...themeColors,
        swapGradientStart: mode === 'light' ? 'rgba(255, 72, 110, 1)' : 'rgba(255, 72, 110, 0.8)',
        swapGradientEnd: mode === 'light' ? 'rgba(255, 255, 255, 0)' : 'rgba(0, 0, 0, 0)',
        swapBackground: mode === 'light' ? '#FFFFFF' : '#17141D',
      },
    },
    typography: { fontFamily: ["Inter", "sans-serif"].join(",") },
  });
};

export const lightTheme = createCustomTheme('light');
export const darkTheme = createCustomTheme('dark');