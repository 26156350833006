import { createContext, useContext, useState, useEffect } from "react";

export const MobileDeviceDetectContext = createContext();

export const MobileDeviceDetectProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(undefined);

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const testResult = /(iphone|ipad|ipod|android|blackberry|windows phone|ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);
      // setIsMobile(true);
      setIsMobile(testResult);
    };

    handleDeviceDetection();
    window.addEventListener('resize', handleDeviceDetection);

    return () => {
      window.removeEventListener('resize', handleDeviceDetection);
    };
  }, []);

  return (
    <MobileDeviceDetectContext.Provider value={{ isMobile }}>
      {children}
    </MobileDeviceDetectContext.Provider>
  );
};

export const useMobileDeviceDetect = () => {
  return useContext(MobileDeviceDetectContext);
};