import { createContext, useContext, useState, useEffect } from "react";
import SagaChainInfo from '../config/chain.config'
import { SigningStargateClient } from '@cosmjs/stargate'

const KeplrContext = createContext();

export const KeplrProvider = ({ children }) => {
    const keplr = useProvideKeplr();
    return <KeplrContext.Provider value={keplr}>{children}</KeplrContext.Provider>;
};

export const useKeplr = () => {
    return useContext(KeplrContext);
};

function useProvideKeplr() {
    const [keplrState, setKeplrState] = useState({
        isKeplrInstalled: false,
        isKeplrSetup: false,
        chainAdded: false,
        account: null
    });

    const [installModalOpen, setInstallModalOpen] = useState(false);
    const [connectWalletModalOpen, setConnectWalletModalOpen] = useState(false);

    useEffect(() => {
        
        const checkKeplr = async () => {
            if (typeof window.keplr !== "undefined") {
                setKeplrState((prevState) => ({
                    ...prevState,
                    isKeplrInstalled: true
                }));

                if (window.localStorage.getItem('autoConnect') === 'true') {
                    connectKeplr();
                }
            }
        };
        // Keplr may not yet be available, add listener to execute after document is fully loaded
        if (document.readyState === "complete") {
            // If document has finished loading, check Keplr immediately
            checkKeplr();
        } else {
            // If document hasn't finished loading, wait for it to complete
            window.addEventListener("load", checkKeplr);
        }

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener("load", checkKeplr);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("keplr_keystorechange", connectKeplr);

        return () => {
            window.removeEventListener("keplr_keystorechange", connectKeplr);
        };
    }, []);

    async function disconnectKeplr() {
        setKeplrState({
            isKeplrInstalled: true,
            isKeplrSetup: false,
            chainAdded: false,
            account: null,
            balance: 0
        });
    }

    async function connectKeplr() {
        // Check if Keplr has been installed
        if (typeof window.keplr === "undefined") {

            setInstallModalOpen(true);
            setKeplrState((prevState) => ({
                ...prevState,
                isKeplrInstalled: false
            }));

            return;
        }

        setKeplrState((prevState) => ({
            ...prevState,
            isKeplrInstalled: true
        }));

        try {
            await window.keplr.enable(SagaChainInfo.chainId);

            const offlineSigner = window.keplr.getOfflineSigner(SagaChainInfo.chainId);
            const accounts = await offlineSigner.getAccounts();

            if (accounts.length === 0) {
                setConnectWalletModalOpen(true);
                return;
            }

            const client = await SigningStargateClient.connectWithSigner(
                SagaChainInfo.rpc,
                offlineSigner
            )
            const balance = await client.getBalance(accounts[0].address, SagaChainInfo.stakeCurrency.coinMinimalDenom);

            setKeplrState((prevState) => ({
                ...prevState,
                isKeplrSetup: true,
                account: accounts[0],
                balance: balance.amount / Math.pow(10, SagaChainInfo.stakeCurrency.coinDecimals)
            }));
        } catch (error) {
            if (error.message === `key doesn't exist`) {
                setConnectWalletModalOpen(true);
                return;
            } else if (error.message === `There is no chain info for ${SagaChainInfo.chainId}`) {
                try {
                    await window.keplr.experimentalSuggestChain(SagaChainInfo);
                    connectKeplr();
                    return;
                } catch (error) {
                    throw error;
                }
            }

            throw error;
        }

        window.localStorage.setItem('autoConnect', true); // autoconnect for future visits

        setKeplrState((prevState) => ({
            ...prevState,
            chainAdded: true
        }));
    }

    async function updateAccountBalance() {
        try {
            const offlineSigner = window.keplr.getOfflineSigner(SagaChainInfo.chainId);
            const client = await SigningStargateClient.connectWithSigner(
                SagaChainInfo.rpc,
                offlineSigner
            )
            const balance = await client.getBalance(keplrState.account.address, SagaChainInfo.stakeCurrency.coinMinimalDenom);

            if (keplrState.balance !== (balance.amount / Math.pow(10, SagaChainInfo.stakeCurrency.coinDecimals))) {
                setKeplrState((prevState) => ({
                    ...prevState,
                    balance: balance.amount / Math.pow(10, SagaChainInfo.stakeCurrency.coinDecimals)
                }));
            }
        } catch (e) {
            console.log("Error getting balance:" + e.message);

            if (keplrState.balance !== 0) {
                setKeplrState((prevState) => ({
                    ...prevState,
                    balance: 0
                }));
            }
        }
    }

    return {
        keplrState,
        connectKeplr,
        disconnectKeplr,
        updateAccountBalance,
        installModalOpen,
        setInstallModalOpen,
        connectWalletModalOpen,
        setConnectWalletModalOpen
    };
}
