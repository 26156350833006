import { createContext, useContext, useState } from "react";

const AccountDrawerContext = createContext();

export const AccountDrawerProvider = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <AccountDrawerContext.Provider value={{ drawerOpen, toggleDrawer }}>
            {children}
        </AccountDrawerContext.Provider>
    );
};

export const useAccountDrawer = () => {
    return useContext(AccountDrawerContext);
};
