import React from 'react';
import Box  from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';

const WarningContainer = styled(Box)`
padding: 12px 0px;
width: 100%;
font-size: 16px;
font-variant: none;
color: ${({ theme }) => theme.palette.colors.text1};
box-sizing: border-box;
font-family: 'Inter',sans-serif;
width: 100%;
position: fixed;
bottom: 0px;
z-index: 9999;
display: flex;
flex-wrap: nowrap;
background-color: #ffcc00;
justify-content: center;
`;

const WarningBanner = () => {
    return (
        <WarningContainer>
            <Typography sx={{color: 'black'}}>The Saga Platform Chain may currently be experiencing some issues. If you are having problems, please try again later. We apologize for any inconvenience.</Typography>
        </WarningContainer>
    );
}

export default WarningBanner;