import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/base';
import styled from '@mui/system/styled';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { useTheme } from '@emotion/react';
import useTheme from '@mui/system/useTheme';

import { ReactComponent as SagaIcon } from '../resources/saga-icon.svg';

import abbreviatePublicAddress from '../utils/abbreviatePublicAddress';

import { useKeplr } from '../hooks/useKeplr';
import { useAccountDrawer } from '../hooks/useAccountDrawer';
import { useMobileDeviceDetect } from '../hooks/useMobileDeviceDetect';

import NavbarSearch from './NavbarSearch';

const NavContainer = styled(Box)`
    padding: 12px 0px;
    width: 100%;
    font-size: 16px;
    font-variant: none;
    color: ${({ theme }) => theme.palette.colors.text1};
    box-sizing: border-box;
    font-family: 'Inter',sans-serif;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 1000;
    display: flex;
    flex-wrap: nowrap;
`;

const ButtonBase = styled(Button)`
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 9999px;
    height: 40px;
`;

const ConnectButton = styled(ButtonBase)`
    font-weight: 600;
    font-size: 16px;
    padding: 10px 12px;
    border: none;
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.main};
    transition: color 125ms ease-in 0s;
    &:hover {
        color: ${({ theme }) => theme.palette.primary.light};
    }
`;

const ConnectedButton = styled(ButtonBase)`
    font-size: 16px;
    padding: 0.5rem;
    height: 36px;
    margin-right: 2px;
    margin-left: 2px;
    border: none;
    border: 1px solid ${({ theme }) => theme.palette.colors.borderAndFill2};
    background-color: ${({ theme }) => theme.palette.colors.borderAndFill2};
    color: ${({ theme }) => theme.palette.colors.text1};
    font-weight: 500;
    &:hover {
        border: 1px solid ${({ theme }) => theme.palette.colors.border};
    }
    &:focus {
        border: 1px solid ${({ theme }) => theme.palette.colors.border};
    }
    &:active {
        box-shadow: ${({ theme }) => theme.palette.colors.secondary1} 0px 0px 0px 1pt;
        border: 1px solid ${({ theme }) => theme.palette.colors.secondary2};
    }
`;

const GetTokensPillButton = styled(ButtonBase)`
    font-size: 12px;
    padding: 0.5rem;
    height: 28px;
    margin-right: 2px;
    margin-left: 2px;
    border: none;
    background-color: ${({ theme }) => theme.palette.colors.primary1};
    color: ${({ theme }) => theme.palette.colors.text1};
    font-weight: 300;
    border: 1px solid ${({ theme }) => theme.palette.colors.border};
    &:hover {
        background-color: ${({ theme }) => theme.palette.colors.borderAndFill2};
    }
    &:focus {
        border: 1px solid ${({ theme }) => theme.palette.colors.border};
    }
    &:active {
        box-shadow: ${({ theme }) => theme.palette.colors.secondary1} 0px 0px 0px 1pt;
        border: 1px solid ${({ theme }) => theme.palette.colors.secondary2};
    }
`;

const HelpLink = styled('a')`
font-family: 'Inter', sans-serif;
font-weight: 500;
user-select: none;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
    font-size: 12px;

    margin-right: 2px;
    margin-left: 2px;
    border: none;

    color: ${({ theme }) => theme.palette.colors.text1};
    font-weight: 300;
    text-decoration: none !important;

    &:hover {
        font-weight: 400;
        text-decoration: none !important;
    }
    &:focus {
        text-decoration: none !important;
    }
    &:active {
        text-decoration: none !important;
    }
`;

const MenuLink = styled(Link)`
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    margin: 4px 0;
    width: 100%;
    padding: 8px 14px;
    gap: 4px;
    display: flex;
    white-space: nowrap;
    text-align: center;
    line-height: 24px;
    text-decoration: none;
    border-radius: 12px;
    transition: 250ms;
    &:hover {
        background: ${({ theme }) => theme.palette.background.hover};
    }
`;

const SearchContainer = styled(Box)`
    display: flex;
    align-self: center;
`;

const BaseSideContainer = styled(Box)`
    display: flex;
    flex: 1;
    align-items: center;
`;

const LeftSideContainer = styled(BaseSideContainer)`
    justify-content: flex-start;
    margin-left: 12px;
`;

const RightSideContainer = styled(BaseSideContainer)`
    justify-content: flex-end;
    margin-right: 12px;
`;

const Navbar = () => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const { keplrState } = useKeplr();
    const { toggleDrawer } = useAccountDrawer();
    const { isMobile } = useMobileDeviceDetect();

    const handleClicked = async () => toggleDrawer();

    const [scrolled, setScrolled] = useState(false);
    const handleScroll = () => setScrolled(window.scrollY > 1); // Handle scrolling, adjust navbar background if the user scrolls 

    useEffect(() => {
        window.addEventListener('scroll', handleScroll); // Scroll event listener
        return () => window.removeEventListener('scroll', handleScroll); // Cleanup
    }, []);

    return (
        <NavContainer sx={{ backgroundColor: (scrolled || pathname.startsWith('/swap')) ? theme.palette.colors.white : 'transparent', borderBottom: (scrolled || pathname.startsWith('/swap')) ? `1px solid ${theme.palette.colors.borderAndFill}` : 'none' }}>

            <LeftSideContainer>
                <Link to="/">
                    <Box sx={{ marginRight: '12px', display: 'flex', alignItems: 'center' }}>
                        <SagaIcon
                            fill={theme.palette.colors.black}
                            height="24px"
                        />
                    </Box>
                </Link>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {!isMobile &&
                        <>
                            <MenuLink sx={{ color: pathname.startsWith('/launch') ? 'text.primary' : 'text.secondary' }} to="/launch">Launch</MenuLink>
                            <MenuLink sx={{ color: pathname.startsWith('/chainlets') ? 'text.primary' : 'text.secondary' }} to="/chainlets">Chainlets</MenuLink>
                            <MenuLink sx={{ color: pathname.startsWith('/swap') ? 'text.primary' : 'text.secondary' }} to="/swap">Saga Swap</MenuLink>
                        </>
                    }
                </Box>
            </LeftSideContainer>
            <SearchContainer>
            { (!isMobile && !pathname.startsWith('/swap')) && <NavbarSearch />}
            { pathname.startsWith('/swap') && <Typography sx={{ fontWeight: '800', color: '#FF486E', fontSize: '28px' }}>SAGA SWAP</Typography> }
            { isMobile && <Typography sx={{ fontWeight: '800' }}>SAGA WEB APP</Typography> }
            </SearchContainer>
            <RightSideContainer>
                {
                    pathname.startsWith('/swap') ?
                        <></>
                        :
                        isMobile ?
                            <>
                                <IconButton
                                    size="large"
                                    edge="end"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={handleClicked}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </>
                            :
                            <>
                                {keplrState.account ?
                                    <>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <GetTokensPillButton onClick={handleClicked}>Get Tokens!</GetTokensPillButton>
                                            { /* <HelpLink href="Saga Docs">Need help?</HelpLink> */}
                                        </Box>
                                        <ConnectedButton onClick={handleClicked}>
                                            <AccountCircleIcon sx={{ padding: '0px 8px' }} />
                                            <Typography sx={{ paddingRight: '8px', lineHeight: 'inherit', fontFamily: `'Inter',sans-serif`, fontSize: '1rem', fontWeight: '500' }}>
                                                {abbreviatePublicAddress(keplrState.account.address, 6, 4)}
                                            </Typography>
                                        </ConnectedButton>
                                    </> :
                                    <ConnectButton onClick={handleClicked} color="inherit">Connect</ConnectButton>}
                            </>
                }
            </RightSideContainer>
        </NavContainer>
    );
}

export default Navbar;