import Box from '@mui/material/Box';
import styled from '@mui/system/styled';

const Container = styled(Box)`
    color: ${({ theme }) => theme.palette.colors.text2};
    padding: 16px;
`;

const Header = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
`;

const Content = styled(Box)`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const AccountDrawerContainer = ({ children, title }) => {
    return (
        <Container>
            <Header>
                {title}
            </Header>
            <Content>
                {children}
            </Content>
        </Container>
    );
};

export default AccountDrawerContainer;