import { useEffect, useState, useRef } from 'react';

import styled from '@mui/system/styled';
import useTheme from '@mui/system/useTheme';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

import { useAccountDrawer } from '../hooks/useAccountDrawer';
import { useKeplr } from '../hooks/useKeplr';
import { useMobileDeviceDetect } from '../hooks/useMobileDeviceDetect';

import AccountDrawerWalletPanel from './AccountDrawerWalletPanel';
import AccountDrawerAccountPanel from './AccountDrawerAccountPanel';
import AccountDrawerSettingsPanel from './AccountDrawerSettingsPanel';
import AccountDrawerMobilePanel from './AccountDrawerMobilePanel';

const Container = styled(Box)`
    display: flex;
    flex-direction: row;
    height: calc(100% - 16px);
    position: fixed;
    right: 8px;
    top: 8px;
    z-index: 1030;
`;

const AccountDrawerWrapper = styled(Box)`
    border-radius: 12px;
    width: 320px;
    background-color: ${({ theme }) => theme.palette.common.backgroundSurface};
    border: 1px solid ${({ theme }) => theme.palette.common.backgroundOutline};
    box-shadow: ${({ theme }) => theme.palette.common.deepShadow};
`;

const CloseDrawer = styled(Box)`
    cursor: pointer;
    padding: 24px 28px 24px 14px;
    border-radius: 20px 0 0 20px;
    &:hover {
        z-index: -1;
        margin: 0 -8px 0 0;
        background-color: ${({ theme }) => theme.palette.common.stateOverlayHover};
    }
`;

const AccountDrawer = ({ toggleThemeType, themeType }) => {
  const { drawerOpen, toggleDrawer } = useAccountDrawer();
  const { keplrState } = useKeplr();
  const theme = useTheme();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const drawerContainer = useRef(null);
  const { isMobile } = useMobileDeviceDetect();

  const closeDrawer = () => {
    toggleDrawer();
    setSettingsOpen(false);
  };

  useEffect(() => {
    const escapeKeyDownHandler = (event) => {
      if (event.key === 'Escape' && drawerOpen) {
        event.preventDefault();
        toggleDrawer();
        setSettingsOpen(false);
      }
    };

    if (drawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener('keydown', escapeKeyDownHandler);
      return () => {
        document.removeEventListener('keydown', escapeKeyDownHandler);
        return () => document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [drawerOpen]);

  const handleClickOutside = (event) => {
    if (drawerContainer.current && !drawerContainer.current.contains(event.target)) {
      toggleDrawer();
      setSettingsOpen(false);
    }
  };

  return (
    <Slide ref={drawerContainer} direction="left" in={drawerOpen} mountOnEnter unmountOnExit>
      <Container>
        {!isMobile && <CloseDrawer onClick={closeDrawer}>
          <KeyboardDoubleArrowRightIcon sx={{ fill: theme.palette.colors.text4 }} />
        </CloseDrawer>}
        <AccountDrawerWrapper>
          {isMobile ?
            <>
              {!settingsOpen && <AccountDrawerMobilePanel setSettingsOpen={setSettingsOpen} toggleThemeType={toggleThemeType} themeType={themeType} />}
            </>
            :
            <>
              {keplrState.account && !settingsOpen && <AccountDrawerAccountPanel setSettingsOpen={setSettingsOpen} />}
              {!keplrState.account && !settingsOpen && <AccountDrawerWalletPanel setSettingsOpen={setSettingsOpen} />}
            </>
          }
          {settingsOpen && <AccountDrawerSettingsPanel setSettingsOpen={setSettingsOpen} toggleThemeType={toggleThemeType} themeType={themeType} />}
        </AccountDrawerWrapper>
      </Container>
    </Slide>
  );
};

export default AccountDrawer;