import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import useTheme  from '@mui/system/useTheme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useKeplr } from '../hooks/useKeplr';
import { useAccountDrawer } from '../hooks/useAccountDrawer';

import AccountDrawerContainer from './AccountDrawerContainer';
import OptionButton from './OptionButton';
import ToggleSwitch from './ToggleSwitch';

const RowContent = styled(Box)`
    align-items: center;
    font-size: 16px;
    color: ${({ theme }) => theme.palette.colors.text1};
    box-sizing: border-box;
    display: flex;
    gap: 12px;
    padding-top: 12px;
    height: 52px;
`;

const SettingDescription = styled(Typography)`
    color: ${({ theme }) => theme.palette.colors.text2};
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    width: 150px;
    padding-left: 5px;
`;

const AccountDrawerSettingsPanel = ({ setSettingsOpen, toggleThemeType, themeType }) => {
    const { disconnectKeplr } = useKeplr();
    const { toggleDrawer } = useAccountDrawer();
    const theme = useTheme();

    const switchOptions = [
        { label: 'Light', value: 'light' },
        { label: 'Dark', value: 'dark' },
      ];

    const handleDisconnectClick = async () => {
        await disconnectKeplr();
        window.localStorage.setItem('autoConnect', false);
        setSettingsOpen(false);
        toggleDrawer();
    };

    return (
        <AccountDrawerContainer
            title={
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <ArrowBackIcon
                        sx={{
                            flex: '1',
                            fill: theme.palette.colors.text2,
                            cursor: 'pointer',
                            paddingLeft: '5px',
                            paddingRight: '90px',
                            "&:hover": { fill: theme.palette.colors.text4, transitionDuration: '125ms', opacity: '0.6' }
                        }}
                        onClick={() => setSettingsOpen(false)} />
                    <Typography sx={{ color: theme.palette.colors.text2 }}>Settings</Typography>
                </Box>
            }
        >
            <RowContent>
                <SettingDescription>Disconnect:</SettingDescription>
                <OptionButton type="power" onClick={handleDisconnectClick} />
            </RowContent>
            <RowContent>
                <SettingDescription>Select a theme:</SettingDescription>
                <ToggleSwitch options={switchOptions} activeOption={themeType} onToggle={toggleThemeType} />
            </RowContent>
        </AccountDrawerContainer>
    );
};

export default AccountDrawerSettingsPanel;