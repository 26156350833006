const opacify = (amount, hexColor) => {
    if (!hexColor.startsWith('#')) {
        return hexColor
    }

    if (hexColor.length !== 7) {
        throw new Error(`opacify: provided color ${hexColor} was not in hexadecimal format (e.g. #000000)`)
    }

    if (amount < 0 || amount > 100) {
        throw new Error('opacify: provided amount should be between 0 and 100')
    }

    const opacityHex = Math.round((amount / 100) * 255).toString(16)
    const opacifySuffix = opacityHex.length < 2 ? `0${opacityHex}` : opacityHex

    return `${hexColor.slice(0, 7)}${opacifySuffix}`
}

export const colors = (mode) => {
    return {
        primary: {
            light: mode === 'light' ? light.primaryLight : dark.primaryLight,
            main: mode === 'light' ? light.primaryMain : dark.primaryMain,
        },
        secondary: {
            main: mode === 'light' ? light.secondaryMain : dark.secondaryMain,
        },
        text: {
            primary: mode === 'light' ? light.textPrimary : dark.textPrimary,
            secondary: mode === 'light' ? light.textSecondary : dark.textSecondary,
            tertiary: mode === 'light' ? light.textTertiary : dark.textTertiary,
        },
        background: {
            default: mode === 'light' ? light.backgroundDefault : dark.backgroundDefault,
            hover: mode === 'light' ? light.backgroundHover : dark.backgroundHover,
        },
        common: {
            hoverState: opacify(24, mode === 'light' ? light.hoverState : dark.hoverState),
            backgroundSurface: mode === 'light' ? light.backgroundSurface : dark.backgroundSurface,
            deepShadow: `8px 12px 20px ${mode === 'light' ? light.deepShadow1 : dark.deepShadow1}, 4px 6px 12px ${mode === 'light' ? light.deepShadow2 : dark.deepShadow2}, 4px 4px 8px ${mode === 'light' ? light.deepShadow1 : dark.deepShadow1}`,
            backgroundOutline: mode === 'light' ? light.backgroundOutline : dark.backgroundOutline,
            accentActive: mode === 'light' ? light.accentActive : dark.accentActive,
            accentActiveSoft: opacify(24, mode === 'light' ? light.accentActiveSoft : dark.accentActiveSoft),
            accentAction: mode === 'light' ? light.accentAction : dark.accentAction,
            backgroundModule: mode === 'light' ? light.backgroundModule : dark.backgroundModule,
            backgroundInteractive: mode === 'light' ? light.backgroundInteractive : dark.backgroundInteractive,
            backgroundOutline: mode === 'light' ? light.backgroundOutline : dark.backgroundOutline,
            backgroundScrim: opacify(60, mode === 'light' ? light.backgroundScrim : dark.backgroundScrim),
            stateOverlayHover: opacify(8, mode === 'light' ? light.stateOverlayHover : dark.stateOverlayHover),
        },

        black:  mode === 'light' ? '#000000' : '#FFFFFF',
        
        transparentBlack1: 'rgba(0, 0, 0, 0.55)', 
        transparentBlack2: 'rgba(0, 0, 0, 0.075)', 
        transparentBlack3: 'rgba(0, 0, 0, 0.01)', 
        transparentBlack4: 'rgba(0, 0, 0, 0.04)', 

        white: mode === 'light' ? '#FFFFFF' : '#0d111c',
        whiteFaded: mode === 'light' ? '#FEFEFE' : '#010101',
        backgroundGradient1: mode === 'light' ? light.backgroundGradient1 : dark.backgroundGradient1, 
        backgroundGradient2: mode === 'light' ? light.backgroundGradient2 : dark.backgroundGradient2, 

        text1: mode === 'light' ? light.text1 : dark.text1,
        text2: mode === 'light' ? light.text2 : dark.text2,
        text3: mode === 'light' ? light.text3 : dark.text3,
        text4: mode === 'light' ? light.text4 : dark.text4,
        text5: mode === 'light' ? light.text5 : dark.text5,
        border: mode === 'light' ? light.border : dark.border,
        borderAndFill: mode === 'light' ? light.borderAndFill : dark.borderAndFill,
        borderAndFill2: mode === 'light' ? light.borderAndFill2 : dark.borderAndFill2,

        primary1: mode === 'light' ? light.primaryColor1 : dark.primaryColor1, 
        primary2: mode === 'light' ? light.primaryColor2 : dark.primaryColor2, 
        primary3: mode === 'light' ? light.primaryColor3 : dark.primaryColor3, 
        primaryTransparent1: mode === 'light' ? light.transparentBlue1 : dark.transparentBlue1, 
        primaryTransparent2: mode === 'light' ? light.transparentBlue2 : dark.transparentBlue2, 
        primaryTransparent3: mode === 'light' ? light.transparentBlue3 : dark.transparentBlue3, 

        secondary1: mode === 'light' ? light.secondaryColor1 : dark.secondaryColor1, 
        secondary2: mode === 'light' ? light.secondaryColor2 : dark.secondaryColor2, 
        secondary3: mode === 'light' ? light.secondaryColor3 : dark.secondaryColor3, 
        secondaryTransparent1: mode === 'light' ? light.transparent1 : dark.transparent1, 
        secondaryTransparent2: mode === 'light' ? light.transparent2: dark.transparent2, 
    }
};

const lightHueAdjustment = (r, g, b, a) => {
    r = Math.min(Math.max(Math.floor(0 + (r * 1)),0),255)
    g = Math.min(Math.max(Math.floor(0 + (g * 1)),0),255)
    b = Math.min(Math.max(Math.floor(0 + (b * 1)),0),255)
    return `rgba(${r},${g},${b},${a})`
}

const light = {
    text1: "#0D111C",
    text2: "#363a47",
    text3: "#98A1C0",
    text4: "#7780a0",
    text5: "#6779FF",
    border: "#a7b1d4",
    borderAndFill: "#D2D9EE",
    borderAndFill2: "#f5f6fc",
    primaryLight: lightHueAdjustment(0,0,255,0.12),
    primaryMain: lightHueAdjustment(0,0,255,1),
    secondaryMain: "#24E2D7",
    textPrimary: "rgba(0, 0, 0, 0.87)",
    textSecondary: "rgba(0, 0, 0, 0.55)",
    textTertiary: "#98A1C0",
    backgroundDefault: "#faf9fa",
    backgroundHover: "#99a1bd14",
    hoverState: "#ADBCFF",
    backgroundSurface: "#FFFFFF",
    deepShadow1: "rgba(51, 53, 72, 0.04)",
    deepShadow2: "rgba(51, 53, 72, 0.02)",
    backgroundOutline: "#D2D9EE",
    accentActive: "#4C82FB",
    accentActiveSoft: "#4C82FB",
    accentAction: lightHueAdjustment(0,0,255,1),
    backgroundModule: "#F5F6FC",
    backgroundInteractive: "#E8ECFB",
    backgroundScrim: "#0D111C",
    stateOverlayHover: "#98A1C0",
    primaryColor1: "#e8ecfb",
    primaryColor2: "#4C82FB",
    primaryColor3: "#4673fa",
    transparentBlue1: "rgba(173, 188, 255, 0.24)",
    transparentBlue2: "rgba(152, 161, 192, 0.08)",
    transparentBlue3: "rgba(47, 128, 237, 0.1)",
    secondaryColor1: lightHueAdjustment(0,0,255,1),
    secondaryColor2: lightHueAdjustment(0,0,255,1),
    secondaryColor3: lightHueAdjustment(0,0,255,1),
    transparent1: lightHueAdjustment(0,0,255,0.51),
    transparent2: lightHueAdjustment(0,0,255,0.12),
    backgroundGradient1: "rgba(255, 255, 255, 0)",
    backgroundGradient2: lightHueAdjustment(0,0,255,0.12),
}

window.light = JSON.stringify(light, null, 4)

const dark = {
    text1: '#E2E1E6',
    text2: '#B8BAC1', 
    text3: '#67798E', 
    text4: '#566470', 
    text5: '#98a1ff', 
    border: '#5C5C60', 
    borderAndFill: 'rgba(152, 161, 192, 0.24)',
    borderAndFill2: 'rgb(19, 26, 42)', 
    primaryLight: 'rgba(76, 130, 251, .12)',
    primaryMain: 'rgba(76, 130, 251, 1)',
    secondaryMain: '#00916E',
    textPrimary: 'rgba(255, 255, 255, 0.87)',
    textSecondary: 'rgba(255, 255, 255, 0.6)',
    textTertiary: '#67798E',
    backgroundDefault: '#17141D',
    backgroundHover: '#99a1bd14',
    hoverState: "#425BC4",
    backgroundSurface: '#1D1B24',
    deepShadow1: 'rgba(51, 53, 72, 0.04)',
    deepShadow2: 'rgba(51, 53, 72, 0.02)',
    backgroundOutline: '#3C3A48',
    accentActive: '#7187D4',
    accentActiveSoft: '#7187D4',
    accentAction: '#D4357C',
    backgroundModule: '#2B2A30',
    backgroundInteractive: '#3C3A48',
    backgroundOutline: "#3C3A48",
    backgroundScrim: '#E2E1E6',
    stateOverlayHover: "#67798E",
    primaryColor1: '#36425A',
    primaryColor2: '#7187D4',
    primaryColor3: '#4A66A0',
    transparentBlue1: 'rgba(65, 91, 178, 0.24)',
    transparentBlue2: 'rgba(71, 97, 128, 0.08)',
    transparentBlue3: 'rgba(29, 78, 128, 0.1)',
    secondaryColor1: '#dde0f6',
    secondaryColor2: '#a4b2fb',
    secondaryColor3: 'rgb(76, 130, 251)',
    transparent1: 'rgba(128, 51, 77, 0.51)',
    transparent2: 'rgba(76, 130, 251, .12)',
    backgroundGradient2: 'rgba(32, 39, 56, 1)', 
    backgroundGradient1: 'rgba(7, 8, 22, 1)',
};

