import React from 'react';

import { Button } from '@mui/base';
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useKeplr } from '../hooks/useKeplr';

import AccountDrawerContainer from './AccountDrawerContainer';
import OptionButton from './OptionButton';

const OptionCardClickable = styled(Button)`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.common.backgroundModule};
    border-radius: 12px;
    border: none;
    padding: 18px;
    transition: 125ms;
    cursor: pointer;
    &:hover, &:focus { background-color: ${({ theme }) => theme.palette.common.hoverState}; }
`;

const StyledComponent = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    & > img, span {
        height: 40px; width: 40px; 
    }
`;

const HeaderText = styled(Typography)`
    color: ${({ theme }) => theme.palette.colors.text3};
    font-size: 16px;
    font-weight: 600;
    padding: 0 8px;
`;

const InstallNote = styled(Typography)`
    color: ${({ theme }) => theme.palette.colors.text4};
    font-size: 12px;
    margin-right: 18px;
    padding-top: 18px;
`;

const AccountDrawerWalletPanel = ({ setSettingsOpen }) => {
    const { connectKeplr, keplrState } = useKeplr();

    const handleClick = async () => {
        if (keplrState.isKeplrInstalled) {
            await connectKeplr();
        } else {
            window.open('https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en', "_blank", "noreferrer");
        }
    };

    return (
        <AccountDrawerContainer
            title={
                <>
                    Connect a wallet
                    <OptionButton type="settings" onClick={() => { setSettingsOpen(true) }} />
                </>
            }>
            <OptionCardClickable onClick={handleClick}>
                <StyledComponent>
                    <img src='/keplr-logo.png' alt="Icon" />
                </StyledComponent>
                <HeaderText>{keplrState.isKeplrInstalled ? 'Connect with Keplr' : 'Install Keplr'}</HeaderText>
            </OptionCardClickable>
            {!keplrState.isKeplrInstalled && <InstallNote>Note: You will need to reload the page after installing Keplr.</InstallNote>}
        </AccountDrawerContainer>
    );
};

export default AccountDrawerWalletPanel;